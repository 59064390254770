import {
  AnimatedSpinner,
  Button,
  Form,
  IconPawPrint,
  IconPetSitting,
  IconSearch,
  useDialogHeadingId,
  useLargeFromMediaQuery,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import { useFormikContext } from 'formik';

import {
  RWB_SERVICE_NAMES,
  SEARCH_FILTERS_FORM_ID,
  SERVICE_AND_BOOKING_TYPE_FORM_ID,
} from '~/components/SearchFiltersForm/constants';
import { BookingTypeField } from '~/components/SearchFiltersForm/ui/BookingType/BookingTypeField';
import { DateSelector } from '~/components/SearchFiltersForm/ui/DateSelector/DateSelector';
import { DialogFormField } from '~/components/SearchFiltersForm/ui/DialogFormField/DialogFormField';
import { ErrorBoundary } from '~/components/SearchFiltersForm/ui/ErrorBoundary/ErrorBoundary';
import { LocationField } from '~/components/SearchFiltersForm/ui/Location/LocationField';
import { ServiceTypeField } from '~/components/SearchFiltersForm/ui/ServiceType/ServiceTypeField';
import { YourPetsField } from '~/components/SearchFiltersForm/ui/YourPets/YourPetsField';
import {
  transformPetSelectionToString,
  transformServiceOptionsToString,
} from '~/components/SearchFiltersForm/utils';
import { trackEvent } from '~/components/analytics/analytics';
import {
  UPDATE_FILTER_PETS_EVENT_NAME,
  UPDATE_FILTER_SERVICE_EVENT_NAME,
} from '~/components/analytics/constants';
import { SERVICE_OPTIONS_V2 } from '~/components/constants';
import { ProductReview } from '~/components/pages/Home/Hero/components/ProductReview';

import styles from './AdvancedSearchForm.module.css';

import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

const AdvancedSearchForm = (): ReactElement => {
  const { values, handleSubmit, isSubmitting } = useFormikContext<SearchFilters>();

  const dialogHeadingId = useDialogHeadingId();
  const isLargeFromViewport = useLargeFromMediaQuery();
  const isMediumFromViewport = useMediumFromMediaQuery();

  const onSubmit = (): void => {
    handleSubmit();
  };

  const isServiceTypeDisplayValueHasDescription = false;

  const showBookingTypeField = RWB_SERVICE_NAMES.includes(values.service.type);

  return (
    <>
      <div className={styles.root}>
        <Form
          hasHtml5Validation={false}
          hasTighterSpacing
          id={SEARCH_FILTERS_FORM_ID}
          isGridRowGapDisabled={false}
          label={{ id: dialogHeadingId }}
        >
          <div className={styles.formContainer}>
            <div className={styles.searchForm}>
              <div className={styles.breakPoints}>
                <ErrorBoundary fieldName="petTypes">
                  <DialogFormField
                    ctaLabel="Add pets"
                    displayValue={transformPetSelectionToString(values.petTypes, true)}
                    handleSubmit={(): void => trackEvent(UPDATE_FILTER_PETS_EVENT_NAME)}
                    icon={<IconPawPrint />}
                    isLabelVisuallyHidden
                    label="How many pets need caring for?"
                    placeholder="How many pets need caring for?"
                    visuallyHiddenLabel="To change the pet selection, click the field or press ENTER key to launch the pet field in a dialog."
                  >
                    <YourPetsField />
                  </DialogFormField>
                </ErrorBoundary>
              </div>
              <div className={styles.breakPoints}>
                <ErrorBoundary fieldName="location">
                  <LocationField
                    isLabelVisuallyHidden
                    placeholder="Add your street address or suburb"
                  />
                </ErrorBoundary>
              </div>
              {/* By design Dates and Pets fields should swap its places
              for different screen sizes */}
              {isMediumFromViewport && (
                <div className={styles.dates}>
                  <ErrorBoundary fieldName="chronology">
                    <DateSelector
                      dateFormat="fancy"
                      isCalendarIconHidden={false}
                      isLabelVisuallyHidden
                      placeholder="Which days?"
                      rwbStartingFromPlaceholder="Starting from"
                    />
                  </ErrorBoundary>
                </div>
              )}
              <div className={styles.breakPoints}>
                <ErrorBoundary fieldName="service">
                  <DialogFormField
                    displayValue={transformServiceOptionsToString(
                      SERVICE_OPTIONS_V2,
                      values.service.type,
                      values.bookingType,
                      isServiceTypeDisplayValueHasDescription
                    )}
                    handleSubmit={(): void => trackEvent(UPDATE_FILTER_SERVICE_EVENT_NAME)}
                    icon={<IconPetSitting />}
                    isDialogCtaHidden
                    isLabelVisuallyHidden
                    label="Choose the service that suits best"
                    placeholder="Which service are you looking for?"
                    visuallyHiddenLabel="To change the service type, click the field or press ENTER key to launch the service field in a dialog."
                  >
                    <Form id={SERVICE_AND_BOOKING_TYPE_FORM_ID} label={{ id: dialogHeadingId }}>
                      <ServiceTypeField />
                    </Form>
                  </DialogFormField>
                </ErrorBoundary>
              </div>
              {showBookingTypeField && (
                <div className={styles.bookingType}>
                  <BookingTypeField />
                </div>
              )}
              {/* By design Dates and Pets fields should swap its places
              for different screen sizes */}
              {!isMediumFromViewport && (
                <div className={styles.dates}>
                  <ErrorBoundary fieldName="chronology">
                    <DateSelector
                      dateFormat="fancy"
                      isCalendarIconHidden={false}
                      isCheckboxLegendVisuallyHidden
                      isLabelVisuallyHidden
                      placeholder="Which days?"
                      rwbStartingFromPlaceholder="Starting from"
                      weekDaySelectorLabel="Which day(s) would you like to book?"
                    />
                  </ErrorBoundary>
                </div>
              )}

              <div className={styles.searchBtn}>
                <Button
                  iconLeading={
                    isLargeFromViewport ? (
                      isSubmitting ? (
                        <AnimatedSpinner size="medium" />
                      ) : undefined
                    ) : isSubmitting ? (
                      <AnimatedSpinner size="medium" />
                    ) : (
                      <IconSearch size="medium" />
                    )
                  }
                  isDisabled={isSubmitting}
                  isFullBleed
                  isSubmit
                  label="Find a sitter near you"
                  onClick={onSubmit}
                />
              </div>
            </div>
            <ProductReview />
          </div>
        </Form>
      </div>
    </>
  );
};

export { AdvancedSearchForm };
